import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Spinner from '../components/shared/Spinner/SpinnerOverlay.component';
import ErrorBoundary from '../components/error-boundary/Error-boundary.component';
import PostEditPage from "../Pages/Post/PostEdit.page";
import AdminRoute from "./AdminRoute";
import AdminDashboard from "../components/Admin/AdminDashboard/AdminDashboard.container";
import AdminListComments from "../components/Admin/AdminList/AdminListComments/AdminListComments.container";
import AdminListProfiles from "../components/Admin/AdminList/AdminListProfiles/AdminListProfiles.container";
import AdminListPosts from "../components/Admin/AdminList/AdminListPosts/AdminListPosts.container";

const Home = lazy(() => import('../Pages/Home/Home.container'));
const Auth = lazy(() => import('../Pages/Auth/Auth.container'));
const Chat = lazy(() => import('../Pages/Chat/Chat.container'));
const Message = lazy(() => import('../Pages/Messages/Messages.container'));
const Profile = lazy(() => import('../Pages/Profile/Profile.container'));
const EditProfile = lazy(() => import('../Pages/EditProfile/Edit.container'));
const DisplayAPost = lazy(() =>
  import('../components/Post/DisplayAPost/DisplayAPost.container')
);
const PostEditorContainer = lazy(() =>
    import('../components/PostEditor/PostEditor.container')
);
const Notify = lazy(
  () => import('../Pages/Notification/Notification.container')
  // eslint-disable-next-line
);
const Post = lazy(() => import('../Pages/Post/Post.page'));

const AppRoute = () => (
  <Switch>
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <Route exact path='/auth' component={Auth} />
        <Route exact path='/post/:id' component={DisplayAPost} />
        <AdminRoute exact path='/admin/dashboard' component={AdminDashboard} routeName='admin' />
        <AdminRoute exact path='/admin/posts' component={AdminListPosts} routeName='admin-posts' />
        <AdminRoute exact path='/admin/comments' component={AdminListComments} routeName='admin-comments' />
        <AdminRoute exact path='/admin/profiles' component={AdminListProfiles} routeName='admin-profiles' />
        <PrivateRoute exact path='/post/:id/edit' component={PostEditPage} />
        <Route exact path='/:name' component={Profile} />
        <Route exact path='/' component={Home} routeName='home' />
        <PrivateRoute exact path='/direct/message' component={Chat} />
        <PrivateRoute exact path='/create/post' component={Post} />
        <PrivateRoute exact path='/me/notification' component={Notify} />
        <PrivateRoute exact path='/message/:id' component={Message} />
        <PrivateRoute exact path='/profile/edit' component={EditProfile} />
      </Suspense>
    </ErrorBoundary>
  </Switch>
);

export default AppRoute;
