// eslint-disable-next-line
export const profileActionTypes = {
  GET_PROFILE_START: 'GET_PROFILE_START',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_MODAL_VISIBLE: 'SET_MODAL_VISIBLE',
  EMPTY_UP_PROFILE: 'EMPTY_UP_PROFILE',
  UPLOAD_IMAGE_START: 'UPUPLOAD_IMAGE_START',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE: 'UPLOAD_IMAGE_FAILURE',
  EDIT_PROFILE_START: 'EDIT_PROFILE_START',
  EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',
  EDIT_PROFILE_FAILURE: 'EDIT_PROFILE_FAILURE',
  FOLLOW_START: 'FOLLOW_START',
  FOLLOW_SUCCESS: 'FOLLOW_SUCCESS',
  FOLLOW_FAILURE: 'FOLLOW_FAILURE',
  SET_NOTIFICATION_START: 'SET_NOTIFICATION_START',
  SET_NOTIFICATION_SUCCESS: 'SET_NOTIFICATION_SUCCESS',
  FETCH_NOTIFICATION_START: 'FETCH_NOTIFICATION_START',
  FETCH_NOTIFICATION_SUCCESS: 'FETCH_NOTIFICATION_SUCCESS',
  FETCH_NOTIFICATION_FAILURE: 'FETCH_NOTIFICATION_FAILURE',
  CREATE_POST_START: 'CREATE_POST_START',
  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  GET_POST_START: 'GET_POST_START',
  GET_FEED_START: 'GET_FEED_START',
  GET_POST_SUCCESS: 'GET_POST_SUCCESS',
  GET_POST_FAILURE: 'GET_POST_FAILURE',
  LIKE_POST_START: 'LIKE_POST_START',
  LIKE_POST_SUCCESS: 'LIKE_POST_SUCCESS',
};
