// eslint-disable-next-line
export const userActionTypes = {
  REGISTER_START: 'REGISTER_START',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

  LOAD_USER_START: 'LOAD_USER_START',
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
  AUTH_ERROR: 'AUTH_ERROR',

  SET_APP_LOADING_FALSE: 'SET_APP_LOADING_FALSE',
};
