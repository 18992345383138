import React from 'react';

import AdminSidebarComponent from "../../AdminSidebar/AdminSidebar.component";
import {AdminContent, AdminPageContainer} from "../../Admin.style";
import Icon from "../../../shared/Icon/Icon.style";
import {t} from "i18next";
import {
    AdminListContainer,
    AdminListPagination,
    AdminListTable,
    AdminListTableActionsCell,
    AdminListTableBody,
    AdminListTableBodyCell,
    AdminListTableBodyRow,
    AdminListTableButton,
    AdminListTableHead,
    AdminListTableHeadCell,
    AdminListTableHeadRow
} from "../AdminList.style";


const AdminListCommentsComponent = ({ list, page, pageSize,total, count, totalPages, viewCallback, deleteCallback }) => {

    return (
        <AdminPageContainer>
            <AdminSidebarComponent></AdminSidebarComponent>
            <AdminContent>
                <h1>{t('admin.comments.list')}</h1>
                <AdminListContainer>
                    <AdminListTable>
                        <AdminListTableHead>
                            <AdminListTableHeadRow>
                                <AdminListTableHeadCell>{t('admin.comments.comment')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell>{t('admin.comments.profile')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell>{t('admin.comments.date')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell>{t('admin.comments.post')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell></AdminListTableHeadCell>
                            </AdminListTableHeadRow>
                        </AdminListTableHead>
                        <AdminListTableBody>
                            {list && list.map((comment) => (
                                <AdminListTableBodyRow key={comment._id}>
                                    <AdminListTableBodyCell>{comment.comment}</AdminListTableBodyCell>
                                    <AdminListTableBodyCell>
                                        <a href={`/${comment.profile.username}`} target="_blank">
                                            {comment.profile.username}
                                        </a>
                                    </AdminListTableBodyCell>
                                    <AdminListTableBodyCell>{comment.createdAt}</AdminListTableBodyCell>
                                    <AdminListTableBodyCell>{comment.post.caption}</AdminListTableBodyCell>
                                    <AdminListTableActionsCell>
                                        <a href={`/post/${comment.post._id}#c${comment._id}`} target="_blank">
                                            <AdminListTableButton onClick={viewCallback(comment)}><Icon className='fas fa-eye' fontSize={1} /></AdminListTableButton>
                                        </a>
                                        <AdminListTableButton onClick={deleteCallback(comment)}><Icon className='fas fa-trash' fontSize={1} /></AdminListTableButton>
                                    </AdminListTableActionsCell>
                                </AdminListTableBodyRow>
                            ))}
                        </AdminListTableBody>
                    </AdminListTable>

                    <AdminListPagination>
                        <button disabled={page === 1}>{t('admin.pagination.previous')}</button>
                        <span>{page} / {totalPages}</span>
                        <button disabled={page === totalPages}>{t('admin.pagination.next')}</button>
                        <div className={'right'}>{t('admin.total')}: {total}</div>
                    </AdminListPagination>

                </AdminListContainer>
            </AdminContent>
        </AdminPageContainer>
    );

};

export default AdminListCommentsComponent;
