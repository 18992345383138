import React, {useCallback, useEffect, useState} from 'react';
import PrivateRoute from "../../ApiRoutes/PrivateApi";
import SpinnerOverlay from "../shared/Spinner/SpinnerOverlay.component";
import {NoPost, SinglePost} from "../Post/Post.style";
import PostEditorComponent from "./PostEditor.component";
import { Redirect } from 'react-router';
import {Router} from "react-router-dom";

const PostEditor = ({
                                 id
                             }) => {

    console.log('PostEditorContainer', id);

    const [post, setPost] = useState({
        data: '',
        postEditorOptions: '',
        loading: true,
        postLoading: true,
        editorsLoading: true,
        isEditingPhoto: false,
        caption: '',
    });

    const {data, loading, postEditorOptions, isEditingPhoto, caption} = post;


    const fetchPost = useCallback(async () => {
        try {

            const editorsRes = await PrivateRoute('photo-edit/editors', {}, 'get', false, false);
            const res = await PrivateRoute(`post/${id}`, null, 'get', false, false);
            setPost((prevState) => ({
                ...prevState,
                data: res?.data?.post || '',
                postLoading: false,
                editorsLoading: false,
                postEditorOptions: editorsRes?.data?.editors || '',
                loading: false,
                caption: res?.data?.post?.caption || '',
            }));

        } catch (error) {
            console.log(error);
            setPost((prev) => ({...prev, loading: prev.postLoading || prev.editorsLoading, postLoading: false}));
        }
    }, [id]);

    useEffect(() => {
        fetchPost();
    }, [fetchPost]);


    const photoSaveCallback = (newPhotoUrl) => {
        console.log('photoSaveCallback', newPhotoUrl);
        PrivateRoute(`post/${id}`, {new_image_url: newPhotoUrl}, 'put', true, false)
            .then(() => {
                fetchPost();
            });
    };

    const photoRevertCallback = () => {
        console.log('photoRevertCallback');
        setPost((prev) => ({...prev, isEditingPhoto: false}));
    }

    const openPhotoEditor = () => {
        console.log('openPhotoEditor');
        setPost((prev) => ({...prev, isEditingPhoto: true}));
    }

    const savePost = () => {
        console.log('savePost');
        PrivateRoute(`post/${id}`, {caption: caption}, 'put', true, false)
            .then(() => {
                window.location.href = `/post/${id}`;
            });
    }

    const updateCaption = (e) => {
        if (e.target.value !== caption) {
            setPost((prev) => ({...prev, caption: e.target.value}));
        }
    }

    return loading ? (
        <SpinnerOverlay text='Fetching Post'/>
    ) : data ? (
        <SinglePost>
            <PostEditorComponent
                postId={id}
                isSinglePostView={true}
                postData={data}
                postImageUrl={data?.image[0]?.url}
                postEditorOptions={postEditorOptions}
                photoSaveCallback={photoSaveCallback}
                photoRevertCallback={photoRevertCallback}
                isEditingPhoto={isEditingPhoto}
                openPhotoEditor={openPhotoEditor}
                savePost={savePost}
                caption={caption}
                updateCaption={updateCaption}
            />
        </SinglePost>
    ) : (
        <NoPost>No Post Found</NoPost>
    );

};

export default PostEditor;
