import styled from 'styled-components';

export const PhotoEditorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .saveBtn {
        background: #3dca97;
        border: 1px solid #055344;
        border-radius: 5px;
        padding: 0.8rem 1rem;
        margin: 1rem;
        font-size: 1.5rem;
        font-weight: 400;
        color: #fff;

        &:disabled {
            background: #f0f0f0;
            color: #ccc;
        }
    }
    
    .revertBtn {
        background: #EFEFEF;
        border: 1px solid #055344;
        border-radius: 5px;
        padding: 0.8rem 1rem;
        margin: 1rem;
        font-size: 1.5rem;
        font-weight: 400;
        color: #141414;

        &:disabled {
            background: #f0f0f0;
            color: #ccc;
        }
    }

`;

export const PhotoEditorPictureContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    `;


export const PhotoEditorToolsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    flex-direction: column;
    margin-left: 2rem;
    max-width: 307px;
    
    h3 {
        font-size: 1.5rem;
    }
    
    `;

export const PhotoEditorSelector = styled.div`

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .item {
        
        display: flex;
        flex-direction: column;
        margin: 2% 2% 1rem;
        width: 45%;
        opacity: 0.85;
        //filter: gray saturate(0%) brightness(70%) contrast(1000%);
        transition: opacity 0.3s ease-in-out;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 1;
            border: 1px dashed rgba(0, 0, 0, 0.8);
        }
        
        &.selected {
            opacity: 1;
            border: 2px solid rgba(0, 109, 0, 1);
        }

        img {
            max-width: 100%;
            max-height: 120px;
        }
        
        span {
            font-size: 1.2rem;
            font-weight: 100;
            text-align: center;
            width: 100%;
            overflow: clip;
        }

    }

`;

export const PhotoEditorPrompt = styled.textarea`
    width: 100%;
    max-width: 306px;
    height: 100px;
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #4c4848;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 100;
`;

export const PhotoEditorRunTransform = styled.button`
    background: #3dca97;
    border: 1px solid #055344;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 1rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: #fff;
    min-width: 100px;
    max-width: 280px;
    width: 100%;

    &:disabled {
        background: #f0f0f0;
        color: #ccc;
    }
`;

export const PhotoEditorButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 1rem;
`;

export const PhotoEditorHint = styled.div`
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: 1rem;
    text-align: center;
    color: #221c1c;
`;

export const PhotoEditorSuccess = styled.div`
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 1rem;
    text-align: center;
    color: #056306;
`;

export const PhotoEditorInfo = styled.div`
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: 1rem;
    text-align: center;
    color: #053f63;
`;

export const PhotoEditorError = styled.div`
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 1rem;
    text-align: center;
    color: #b81414;
`;

export const PhotoEditorLoadingBar = styled.progress`
    width: 100%;
    margin-top: 1rem;
`;
