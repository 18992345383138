import React from 'react';
import { Container } from '../../helpers/CommonStyles';
import PostEditorContainer from '../../components/PostEditor/PostEditor.container';

const PostEditPage = ({
                          match: {
                              params: {id},
                          },
                      }) => {
    console.log('Post');
    return (
        <Container>
            <PostEditorContainer id={id}  />
        </Container>
    );
};

export default PostEditPage;
