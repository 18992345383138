import styled from 'styled-components';

export const AdminPageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    height: 100vh;
    background: #f9f9f9;
    `;

export const AdminContent = styled.div`
    display: flex;
    width: 55%;
    background: white;
    padding: 2rem;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: normal;
    align-items: flex-start;
    @media (min-width: 767px) {
        width: 79%;
    }
`;
