import React, {useCallback, useEffect, useState} from 'react';

import SpinnerOverlay from '../../shared/Spinner/SpinnerOverlay.component';
import AdminDashboardComponent from "./AdminDashboard.component";
import ApiRequest from "../../../ApiRoutes/PrivateApi";

const AdminDashboard = ({
                               }) => {

    const [dashboardDataState, setDashboardData] = useState({
        data: null,
        loading: true,
    });

    const {data, loading} = dashboardDataState;


    const fetchDashboardData = useCallback(async () => {
        try {

            const res = await ApiRequest('admin/', {}, 'get', true, false);
            console.log('[OK] Fetch data', res.data);
            setDashboardData((prevState) => ({
                ...prevState,
                data: res?.data?.data || null,
                loading: false,
            }));

        } catch (error) {
            console.log('[ERROR] IN REQ', error);
            setDashboardData((prev) => ({...prev, loading: false}));
        }
    }, []);

    useEffect(async() => {
        await fetchDashboardData();
    }, [fetchDashboardData]);

    return loading ? (
        <SpinnerOverlay text='Loading...'/>
    ) :
        (
            <AdminDashboardComponent dashboardData={data} />
    );
};

export default AdminDashboard;
