import styled from 'styled-components';

export const CountersContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    `;
export const Counter = styled.div`
    display: flex;
    flex-basis: 28%;
    flex-direction: column;
    align-items: center;
    margin: 0 2rem;
    `;
export const CounterNumber = styled.p`
    font-size: 3rem;
    font-weight: 500;
    `;
export const CounterNumber2 = styled.p`
    font-size: 2rem;
    font-weight: 500;
    color: #1eaa47;
`;
export const CounterText = styled.p`
    font-size: 1.6rem;
    font-weight: 100;
    `;
export const CounterIcon = styled.img`
    width: 3rem;
    margin-bottom: 1rem;
    `;