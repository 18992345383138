import React from 'react';
import {formatDistance, formatDistanceStrict} from 'date-fns';
import ProfileImage from '../shared/ProfileImage/ProfileImage.component';
import {
    IconContainer,
    ShowNotif,
} from '../MainNotification/Notification.style';

import {
    Date as Time,
    PostContainer,
    Profile,
    Navigate,
    Name,
    CaptionContainer,
    Caption,
    Post as UserPost,
    Button, PostIconContainer
} from '../Post/Post.style';
import {
    CaptionForm,
    EditPhotoBtn,
    PostEditContainer,
    PostMainContainer,
    PostPictureContainer, PostSaveBtn, PostViewBtn
} from './PostEditor.style';
import {getDateLocale, getLanguage, isAuthenticated} from "../../helpers/helpers";
import {t} from "i18next";
import PhotoEditor from "../PhotoEditor/PhotoEditor.container";
import Icon from "../shared/Icon/Icon.style";

const PostEditorComponent = ({
                                 postId,
                                 postData,
                                 caption,
                                 postEditorOptions,
                                 postImageUrl,
                                 photoSaveCallback,
                                 photoRevertCallback,
                                 isEditingPhoto,
                                 openPhotoEditor,
                                 savePost,
                                 updateCaption
                             }) => {

    console.log('PostEditorCOMPONENT', {
        postId,
        postData,
        postImageUrl,
        postEditorOptions,

    });

    return (
        <PostContainer>

            {!isEditingPhoto &&
                <PostEditContainer>
                    <PostMainContainer>
                        <PostPictureContainer>
                            <img width={'480px;'} id="myimage" src={postImageUrl}/>
                        </PostPictureContainer>
                        <EditPhotoBtn onClick={openPhotoEditor}><Icon
                            className='fas fa-magic'/> Покращити</EditPhotoBtn>
                    </PostMainContainer>

                    <CaptionForm>
                        <textarea placeholder='Опис' onChange={updateCaption}>{caption}</textarea>
                    </CaptionForm>
                    <PostSaveBtn onClick={savePost}><Icon className='fas fa-save'/> Зберегти</PostSaveBtn>
                </PostEditContainer>
            }

            {isEditingPhoto &&
                <PhotoEditor postId={postId}
                             sourcePhotoUrl={postImageUrl}
                             saveCallback={photoSaveCallback}
                             revertCallback={photoRevertCallback}></PhotoEditor>
            }

        </PostContainer>
    );
};

export default PostEditorComponent;
