import React from 'react';
import {formatDistance, formatDistanceStrict} from 'date-fns';
import ProfileImage from '../shared/ProfileImage/ProfileImage.component';
import {
    IconContainer,
    ShowNotif,
} from '../MainNotification/Notification.style'

import {
    Navigate,
} from '../Post/Post.style';
import {getDateLocale, getLanguage, isAuthenticated} from "../../helpers/helpers";
import {t} from "i18next";
import Icon from "../shared/Icon/Icon.style";
import {
    PhotoEditorButtonContainer,
    PhotoEditorContainer, PhotoEditorError, PhotoEditorHint, PhotoEditorInfo, PhotoEditorLoadingBar,
    PhotoEditorPictureContainer, PhotoEditorPrompt, PhotoEditorRunTransform,
    PhotoEditorSelector, PhotoEditorSuccess,
    PhotoEditorToolsContainer
} from "./PhotoEditor.style";
import ApiRequestMethod from "../../ApiRoutes/helper";

const PhotoEditorComponent = ({
                                  photoUrl,
                                  sourcePhotoUrl,
                                  postId,
                                  postEditorOptions,
                                  applyTransformation,
                                  hasChanges,
                                  selectTransformer,
                                  isTransformationRunning,
                                  selectedEditorId,
                                  isReadyForTransform,
                                  prompt,
                                  updatePrompt,
                                  errorMessage,
                                  hintMessage,
                                  infoMessage,
                                  successMessage,
                                  revertChanges,
                                  saveChanges
                              }) => {

    const apiUrl = ApiRequestMethod.url;

    console.log('PhotoEditorComponent', {
        photoUrl,
        sourcePhotoUrl,
        postId,
        postEditorOptions,
        apiUrl,
        selectedEditorId
    });


    return (
        <PhotoEditorContainer>
            <PhotoEditorPictureContainer>
                <Navigate to={'#'}>
                    <img width={'480px;'} id="myimage" src={photoUrl}/>
                </Navigate>
            </PhotoEditorPictureContainer>
            <PhotoEditorToolsContainer>
                <div>
                    <button disabled={!hasChanges || isTransformationRunning}
                            className={'saveBtn'} onClick={saveChanges}>{t('editor.save')}</button>
                    <button className={'revertBtn'}
                            onClick={revertChanges}
                    >
                        {hasChanges ? t('editor.revert') : t('editor.close')}
                    </button>
                </div>
                <div><h3>{t('editor.transform_title')}</h3></div>
                <PhotoEditorSelector>
                    {postEditorOptions.map((editor) => {
                        return <button key={editor.id}
                                       className={'item ' + (selectedEditorId === editor.id ? 'selected' : '')}
                                       onClick={(event) => selectTransformer(editor.id)}>
                            <span style={{pointerEvents: 'none'}}>{editor.title}</span>
                            <img style={{pointerEvents: 'none'}} src={apiUrl + editor.icon} alt={editor.title}/>
                        </button>
                    })}
                </PhotoEditorSelector>

                <div>
                    {isTransformationRunning && <PhotoEditorLoadingBar value={null}/>}
                    {hintMessage && <PhotoEditorHint>{hintMessage}</PhotoEditorHint>}
                    {infoMessage && <PhotoEditorInfo>{infoMessage}</PhotoEditorInfo>}
                    {successMessage && <PhotoEditorSuccess>{successMessage}</PhotoEditorSuccess>}
                    {errorMessage && <PhotoEditorError>{errorMessage}</PhotoEditorError>}
                </div>

                <div>
                    {!selectedEditorId && <PhotoEditorHint>{t('editor.initial_prompt_placeholder')}</PhotoEditorHint>}
                    <PhotoEditorPrompt disabled={!selectedEditorId || isTransformationRunning || hasChanges}
                                       onChange={updatePrompt}
                                       placeholder={selectTransformer ? t('editor.prompt_placeholder') : t('editor.initial_prompt_placeholder')}>
                    </PhotoEditorPrompt>
                </div>

                <PhotoEditorButtonContainer>
                    <PhotoEditorRunTransform disabled={!isReadyForTransform || isTransformationRunning || hasChanges}
                                             onClick={applyTransformation}>{t('editor.apply_transform')}</PhotoEditorRunTransform>
                </PhotoEditorButtonContainer>
            </PhotoEditorToolsContainer>
        </PhotoEditorContainer>
    );
};

export default PhotoEditorComponent;
