import React, {useCallback, useEffect, useState} from 'react';
import SpinnerOverlay from '../../../shared/Spinner/SpinnerOverlay.component';
import ApiRequest from "../../../../ApiRoutes/PrivateApi";
import AdminListProfilesComponent from "./AdminListProfiles.component";

const AdminListProfiles = ({
                        }) => {

    const [listDataState, setListData] = useState({
        list: null,
        page: 1,
        pageSize: 10,
        total: 0,
        count: 0,
        totalPages: 0,
        loading: true,
    });

    const {list, page, pageSize, total, count, totalPages, loading} = listDataState;


    const fetchDataComments = useCallback(async () => {
        try {

            const res = await ApiRequest('admin/profiles?page=' + page, {}, 'get', true, false);
            console.log('[OK] Fetch data', res.data);
            setListData((prevState) => ({
                ...prevState,
                list: res?.data?.data || null,
                total: res?.data?.total || 0,
                count: res?.data?.count || 0,
                page: res?.data?.page || 0,
                pageSize: res?.data?.pageSize || 10,
                totalPages: Math.ceil(res?.data?.total / res?.data?.pageSize) || 0,
                loading: false,
            }));

        } catch (error) {
            console.log('[ERROR] IN REQ', error);
            setListData((prev) => ({...prev, loading: false}));
        }
    }, []);

    useEffect(async() => {
        await fetchDataComments();
    }, [page]);

    const viewCallback = (comment) => () => {
        console.log('View profile', comment);
    };

    const deleteCallback = (comment) => () => {
        console.log('Delete profile', comment);
    }

    const goToPage = (newPage) => () => {
        console.log('Go to page', newPage);
        setListData((prev) => ({...prev, loading: true, page: newPage}));
    }

    return loading ? (
            <SpinnerOverlay text='Loading...'/>
        ) :
        (
            <AdminListProfilesComponent list={list}
                                     page={page} pageSize={pageSize} total={total} count={count} totalPages={totalPages}
                                     viewCallback={viewCallback} deleteCallback={deleteCallback} goToPage={goToPage}
            />
        );
};

export default AdminListProfiles;
