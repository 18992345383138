import React, {useCallback, useEffect, useState} from 'react';
import { debounce } from 'lodash';
import PrivateRoute from "../../ApiRoutes/PrivateApi";
import SpinnerOverlay from "../shared/Spinner/SpinnerOverlay.component";
import {NoPost, SinglePost} from "../Post/Post.style";
import PhotoEditorComponent from "./PhotoEditor.component";
import {t} from "i18next";

const PhotoEditor = ({
                                 sourcePhotoUrl,
                                 postId,
                                saveCallback,
    revertCallback
                             }) => {


    const [photo, setPhoto] = useState({
        sourcePhotoUrl: sourcePhotoUrl,
        photoUrl: '',
        postEditorOptions: '',
        loading: true,
        hasChanges: false,
        isTransformationRunning: false,
        selectedEditorId: '',
        isReadyForTransform: false,
        prompt: '',
        hintMessage: '',
        successMessage: '',
        errorMessage: '',
        infoMessage: '',
        transformRunId: '',
    });

    const {loading, photoUrl, hasChanges,
        selectedEditorId, isReadyForTransform, isTransformationRunning,
        prompt, hintMessage, successMessage, errorMessage, infoMessage, transformRunId    } = photo;


    const fetchEditorData = useCallback(async () => {
        try {

            const editorsRes = await PrivateRoute('photo-edit/editors', {}, 'get', false, false);
            setPhoto((prevState) => ({
                ...prevState,
                photoUrl: sourcePhotoUrl,
                postEditorOptions: editorsRes?.data?.editors || '',
                loading: false,
            }));

            console.log('fetchEditorData', editorsRes);

        } catch (error) {
            console.log(error);
            setPhoto((prev) => ({...prev, loading: false}));
        }
    }, [sourcePhotoUrl]);

    useEffect(() => {
        fetchEditorData();
    }, [fetchEditorData]);

    const setResultPolling = (_runId) => {
        console.log('setResultPolling ' + _runId);
        const interval = setInterval(() => {
            PrivateRoute('photo-edit/transform-status?runId=' + _runId, {}, 'get',
                true, false).then((res) => {
                console.log('setResultPolling Result', res.data);
                if (res.data.status === 'completed') {
                    if (!res.data.outputImageUrl) {
                        alert('[ERROR] no image URL provided in result');
                    }
                    setPhoto((prev) => ({...prev,
                        isTransformationRunning: false,
                        hasChanges: true,
                        photoUrl: res.data.outputImageUrl,
                        errorMessage: '',
                        successMessage: '',
                        hintMessage: '',
                        infoMessage: '',
                    }));
                    clearInterval(interval);
                } else if (res.data.status === 'failed') {
                    setPhoto((prev) => ({...prev,
                        isTransformationRunning: false,
                        errorMessage: t('editor.transformation_failed'),
                        successMessage: '',
                        hintMessage: '',
                        infoMessage: '',
                    }));
                    clearInterval(interval);
                }
            }).catch((error) => {
                console.log('setResultPolling Error', error);
                clearInterval(interval);
            });
        }, 5000);
    }


    const applyTransformation = () => {
        console.log('applyTransformation', selectedEditorId, prompt);
        setPhoto((prev) => ({...prev,
            isTransformationRunning: true,
            errorMessage: '',
            successMessage: '',
            hintMessage: '',
            infoMessage: t('editor.starting_transformation'),
        }));
        PrivateRoute('photo-edit/transform-image', {
            editor: selectedEditorId,
            prompt: prompt,
            postId: postId,
            image: sourcePhotoUrl
        }, 'post', true, false).then((res) => {
            console.log('applyTransformation Success', res.data);

            if (res.data.status === 'running') {
                setPhoto((prev) => ({...prev,
                    infoMessage: t('editor.transformation_running'),
                    successMessage: '',
                    errorMessage: '',
                    hintMessage: '',
                    transformRunId: res.data.runId,
                }));
                setResultPolling(res.data.runId);
            } else if (res.data.status === 'completed') {
                setPhoto((prev) => ({...prev,
                    isTransformationRunning: false,
                    hasChanges: true,
                    photoUrl: res.data.data,
                    transformRunId: res.data.runId,
                    errorMessage: '',
                    successMessage: t('editor.transformation_success'),
                    hintMessage: '',
                    infoMessage: '',
                }));
            } else if (res.data.status === 'failed') {
                setPhoto((prev) => ({...prev,
                    isTransformationRunning: false,
                    infoMessage: '',
                    hintMessage: '',
                    successMessage: '',
                    errorMessage: t('editor.transformation_failed'),
                    transformRunId: res.data.runId,
                }));
            }

        }).catch((error) => {
            console.log('applyTransformation Error', error);
            setPhoto((prev) => ({
                ...prev,
                isTransformationRunning: false,
                hasChanges: false,
                isReadyForTransform: prev.selectedEditorId && prev.prompt.length,
                infoMessage: '',
                hintMessage: '',
                successMessage: '',
                errorMessage: error.response?.data?.message || t('editor.unknown_error')
            }));
        });
    }

    const selectTransformer = (editorId) => {
        if (isTransformationRunning) {
            return;
        }
        setPhoto((prev) => ({...prev,
            selectedEditorId: editorId,
            isReadyForTransform: prev.selectedEditorId && prev.prompt.length,
        }));
    }

    const updatePrompt = debounce((promptEvent) => {
        const newPrompt = promptEvent.target.value;
        setPhoto((prev) => ({...prev,
            isReadyForTransform: prev.selectedEditorId && newPrompt.length,
            prompt: newPrompt
        }));
    }, 250);

    const revertChanges = () => {
        const hadChanges = hasChanges;
        setPhoto((prev) => ({...prev,
            photoUrl: sourcePhotoUrl,
            hasChanges: false,
            isTransformationRunning: false,
            errorMessage: '',
            successMessage: 'Зміни скасовано',
            hintMessage: '',
            infoMessage: '',
            prompt: ''
        }));
        if (revertCallback && !hadChanges) {
            setTimeout(() => {
                revertCallback();
            }, 1000);
        }
    }

    const saveChanges = () => {
        setPhoto((prev) => ({...prev,
            isTransformationRunning: false,
            hasChanges: false,
            errorMessage: '',
            successMessage: 'Збережено',
            hintMessage: '',
            infoMessage: '',
        }));
        if (saveCallback) {
            setTimeout(() => {
                saveCallback(photoUrl);
            }, 3000);
        }
    }

    return loading ? (
        <SpinnerOverlay text='Fetching Photo'/>
    ) : photoUrl ? (
        <PhotoEditorComponent
            postId={postId}
            photoUrl={photoUrl}
            sourcePhotoUrl={sourcePhotoUrl}
            postEditorOptions={photo.postEditorOptions}
            applyTransformation={applyTransformation}
            hasChanges={hasChanges}
            selectTransformer={selectTransformer}
            selectedEditorId={selectedEditorId}
            isReadyForTransform={isReadyForTransform}
            isTransformationRunning={isTransformationRunning}
            prompt={prompt}
            updatePrompt={updatePrompt}
            errorMessage={errorMessage}
            infoMessage={infoMessage}
            hintMessage={hintMessage}
            successMessage={successMessage}
            revertChanges={revertChanges}
            saveChanges={saveChanges}
        />
    ) : (
        <NoPost>No Post Found</NoPost>
    );

};

export default PhotoEditor;
