import React, {useCallback, useEffect, useState} from 'react';
import SpinnerOverlay from '../../../shared/Spinner/SpinnerOverlay.component';
import ApiRequest from "../../../../ApiRoutes/PrivateApi";
import AdminListPostsComponent from "./AdminListPosts.component";
import {t} from 'i18next';

const AdminListPosts = ({
                           }) => {

    const [listDataState, setListData] = useState({
        list: null,
        page: null,
        pageSize: 10,
        total: 0,
        count: 0,
        totalPages: 0,
        loading: true,
    });

    const {list, page, pageSize, total, count, totalPages, loading} = listDataState;


    const fetchDataComments = useCallback(async () => {
        try {

            const res = await ApiRequest('admin/posts', {}, 'get', true, false);
            console.log('[OK] Fetch data', res.data);
            setListData((prevState) => ({
                ...prevState,
                list: res?.data?.data || null,
                total: res?.data?.total || 0,
                count: res?.data?.count || 0,
                page: res?.data?.page || 0,
                pageSize: res?.data?.pageSize || 10,
                totalPages: Math.ceil(res?.data?.total / res?.data?.pageSize) || 0,
                loading: false,
            }));

        } catch (error) {
            console.log('[ERROR] IN REQ', error);
            setListData((prev) => ({...prev, loading: false}));
        }
    }, []);

    useEffect(async() => {
        await fetchDataComments();
    }, [fetchDataComments]);

    const viewCallback = (comment) => () => {
        console.log('View post', comment);
    };

    const deleteCallback = (post) => () => {
        if (confirm(t('post.delete_confirm'))) {
            ApiRequest(`admin/post/${post._id}`, null, 'delete', true, false)
                .then(() => {
                    alert(t('post.deleted'));
                    window.location.reload();
                });
        }
    }

    return loading ? (
            <SpinnerOverlay text='Loading...'/>
        ) :
        (
            <AdminListPostsComponent list={list}
                                        page={page} pageSize={pageSize} total={total} count={count} totalPages={totalPages}
                                        viewCallback={viewCallback} deleteCallback={deleteCallback}
            />
        );
};

export default AdminListPosts;
