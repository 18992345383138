// eslint-disable-next-line
export const groupActionTypes = {
  GET_GROUP_START: 'GET_GROUP_START',
  GET_GROUP_SUCCESS: 'GET_GROUP_SUCCESS',
  GET_GROUP_FAILURE: 'GET_GROUP_FAILURE',

  CREATE_GROUP_START: 'CREATE_GROUP_START',
  CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
  CREATE_GROUP_FAILURE: 'CREATE_GROUP_FAILURE',

  SET_CURRENT_GROUP_START: 'SET_CURRENT_GROUP_START',
  SET_CURRENT_GROUP_SUCCESS: 'SET_CURRENT_GROUP_SUCCESS',
  SET_CURRENT_GROUP_FAILURE: 'SET_CURRENT_GROUP_FAILURE',

  SET_CURRENT_GROUP_NULL: 'SET_CURRENT_GROUP_NULL',
  SET_CURRENT_GROUP_NULL_START: 'SET_CURRENT_GROUP_NULL_START',

  SEND_MESSAGE_START: 'SEND_MESSAGE_START',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',

  GET_MESSAGE_START: 'GET_MESSAGE_START',
  GET_MESSAGE_SUCCESS: 'GET_MESSAGE_SUCCESS',
  GET_MESSAGE_FAILURE: 'GET_MESSAGE_FAILURE',

  GET_REALTIME_MESSAGE_START: 'GET_REALTIME_MESSAGE_START',
  GET_REALTIME_MESSAGE_SUCCESS: 'GET_REALTIME_MESSAGE_SUCCESS',

  GET_NOTIFICATION_START: 'GET_NOTIFICATION_START',
  GET_NOTIFICATION_FAILURE: 'GET_NOTIFICATION_FAILURE',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',

  SET_MESSAGE_SEEN_START: 'SET_MESSAGE_SEEN_START',
  SET_MESSAGE_SEEN_SUCCESS: 'SET_MESSAGE_SEEN_SUCCESS',
  SET_MESSAGE_SEEN_FAILURE: 'SET_MESSAGE_SEEN_FAILURE',

  CLEAR_GROUP: 'CLEAR_GROUP',
};
