import React from 'react';

import AdminSidebarComponent from "../AdminSidebar/AdminSidebar.component";
import {AdminContent, AdminPageContainer} from "../Admin.style";
import {CountersContainer, Counter, CounterIcon, CounterNumber, CounterNumber2, CounterText} from "./AdminDashboard.style";
import Icon from "../../shared/Icon/Icon.style";
import {t} from "i18next";


const AdminDashboardComponent = ({ dashboardData }) => {

    return (
        <AdminPageContainer>
            <AdminSidebarComponent></AdminSidebarComponent>
            <AdminContent>
                <h1>{t('admin.dashboard_title')}</h1>

                <CountersContainer>
                    <Counter>
                        <Icon className='fas fa-image' fontSize={6} />
                        <CounterText>{t('admin.posts_t')}</CounterText>
                        <CounterNumber>{dashboardData.posts.toString()}</CounterNumber>
                        { dashboardData.postsToday &&
                            <CounterNumber2>{dashboardData.postsToday} {t('admin.today')}</CounterNumber2>
                        }
                    </Counter>

                    <Counter>
                        <Icon className='fas fa-comment' fontSize={6} />
                        <CounterText>{t('admin.comments_t')}</CounterText>
                        <CounterNumber>{dashboardData.comments.toString()}</CounterNumber>
                        { dashboardData.commentsToday &&
                            <CounterNumber2>{dashboardData.commentsToday} {t('admin.today')}</CounterNumber2>
                        }
                    </Counter>

                    <Counter>
                        <Icon className='fas fa-user' fontSize={6} />
                        <CounterText>{t('admin.profiles_t')}</CounterText>
                        <CounterNumber>{dashboardData.profiles.toString()}</CounterNumber>
                        { dashboardData.profilesCreatedToday &&
                        <CounterNumber2>{dashboardData.profilesCreatedToday} {t('admin.today')}</CounterNumber2>
                        }
                    </Counter>

                </CountersContainer>

            </AdminContent>
        </AdminPageContainer>
    );

};

export default AdminDashboardComponent;
