import React from 'react';

import AdminSidebarComponent from "../../AdminSidebar/AdminSidebar.component";
import {AdminContent, AdminPageContainer} from "../../Admin.style";
import Icon from "../../../shared/Icon/Icon.style";
import {t} from "i18next";
import {
    AdminListContainer,
    AdminListPagination,
    AdminListTable,
    AdminListTableActionsCell,
    AdminListTableBody,
    AdminListTableBodyCell,
    AdminListTableBodyRow,
    AdminListTableButton,
    AdminListTableHead,
    AdminListTableHeadCell,
    AdminListTableHeadRow
} from "../AdminList.style";


const AdminListProfilesComponent = ({ list,
                                        page,
                                        pageSize,
                                        total,
                                        count,
                                        totalPages,
                                        viewCallback,
                                        deleteCallback,
    goToPage
}) => {

    return (
        <AdminPageContainer>
            <AdminSidebarComponent></AdminSidebarComponent>
            <AdminContent>
                <h1>{t('admin.comments.list')}</h1>
                <AdminListContainer>
                    <AdminListTable>
                        <AdminListTableHead>
                            <AdminListTableHeadRow>
                                <AdminListTableHeadCell>{t('admin.profiles.name')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell>{t('admin.profiles.username')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell>{t('admin.profiles.email')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell>{t('admin.profiles.dateRegistered')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell>{t('admin.profiles.dateUpdated')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell></AdminListTableHeadCell>
                            </AdminListTableHeadRow>
                        </AdminListTableHead>
                        <AdminListTableBody>
                            {list && list.map((profile) => (
                                <AdminListTableBodyRow key={profile._id}>
                                    {/*<AdminListTableBodyCell>{profile.name}</AdminListTableBodyCell>*/}
                                    {/*<AdminListTableBodyCell>{profile.username}</AdminListTableBodyCell>*/}
                                    <AdminListTableBodyCell>{profile.email}</AdminListTableBodyCell>
                                    <AdminListTableBodyCell>{profile.createdAt}</AdminListTableBodyCell>
                                    <AdminListTableBodyCell>{profile.updatedAt}</AdminListTableBodyCell>
                                    <AdminListTableActionsCell>
                                        <AdminListTableButton onClick={viewCallback(profile)}><Icon className='fas fa-eye' fontSize={1} /></AdminListTableButton>
                                        <AdminListTableButton onClick={deleteCallback(profile)}><Icon className='fas fa-trash' fontSize={1} /></AdminListTableButton>
                                    </AdminListTableActionsCell>
                                </AdminListTableBodyRow>
                            ))}
                        </AdminListTableBody>
                    </AdminListTable>

                    <AdminListPagination>
                        <button onClick={goToPage(page - 1)} disabled={page === 1}>{t('admin.pagination.previous')}</button>
                        <span>{page} / {totalPages}</span>
                        <button onClick={goToPage(page + 1)} disabled={page === totalPages}>{t('admin.pagination.next')}</button>
                        <div className={'right'}>{t('admin.total')}: {total}</div>
                    </AdminListPagination>

                </AdminListContainer>
            </AdminContent>
        </AdminPageContainer>
    );

};

export default AdminListProfilesComponent;
