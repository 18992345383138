import styled from 'styled-components';

const LanguageSelectorStyle = styled.h1`
  font-family: 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  padding: 1.5rem 0.5rem;
  color: ${(props) => props.theme.text};
  @media (max-width: 720px) {
    font-family: 'Open Sans Condensed',serif;
    font-size: 2.7rem;
  }
`;

export default LanguageSelectorStyle;
