import { useEffect } from 'react';

const UseAuth = (loadUser, setAuth, history, getNotification) => {
  useEffect(() => {
    if (localStorage.getItem('token')) {
      console.log('UseAuth 1');
      loadUser();
      console.log('UseAuth 3');
      return true;
    }
    setAuth();
    console.log('UseAuth 2');
    console.log(history);
    // history.push('/');
    return false;
  }, [loadUser, setAuth, history]);

  useEffect(() => {
    getNotification();
  }, [getNotification]);
};

export default UseAuth;
