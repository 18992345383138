import styled from 'styled-components';

export const AdminListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    padding-bottom: 4rem;

    .right {
        align-self: flex-end;
        margin-left: 10rem;
    }
    
    `;

export const AdminListPagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    `;

export const AdminListTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 1.4rem;
    `;
export const AdminListTableHead = styled.thead`
    background: #f9f9f9;
    `;
export const AdminListTableHeadRow = styled.tr`
    `;
export const AdminListTableHeadCell = styled.th`
    padding: 1rem;
    text-align: left;
    `;
export const AdminListTableBody = styled.tbody`
    `;
export const AdminListTableBodyRow = styled.tr`
    `;
export const AdminListTableBodyCell = styled.td`
    padding: 1rem;
    border-bottom: 1px solid #f9f9f9;
    `;

export const AdminListTableActionsCell = styled.td`
    padding: 1rem;
    border-bottom: 1px solid #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    `;

export const AdminListTableButton = styled.button`
    padding: 0.5rem 1rem;
    background: rgba(58, 58, 58, 0.19);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 0.5rem;
`;
export const PostImagePreview = styled.img`
  max-width: 7rem;
  max-height: 7rem;
  margin-left: 0.5rem;
  @media (min-width: 767px) {
    max-width: 5rem;
    max-height: 5rem;
  }
`;

