import React from 'react';
import { connect } from 'react-redux';
import {SidebarBottom, SidebarButton, SidebarContainer, SidebarLink, SidebarTop} from "./AdminSidebar.style";
import {Navigate} from "../../Post/Post.style";
import LanguageSelector from "../../shared/LanguageSelector/LanguageSelector.component";
import {t} from "i18next";


const AdminSidebarComponent = ({ profilePic, chatProfile = null, height = 4 }) => {

    return (
        <SidebarContainer>
            <SidebarTop></SidebarTop>
            <SidebarLink href={`/admin/dashboard`}>{t('admin.dashboard')}</SidebarLink>
            <SidebarLink href={`/admin/posts`}>{t('admin.posts_t')}</SidebarLink>
            <SidebarLink href={`/admin/comments`}>{t('admin.comments_t')}</SidebarLink>
            <SidebarLink href={`/admin/profiles`}>{t('admin.profiles_t')}</SidebarLink>
            <SidebarBottom>
                <LanguageSelector />
            </SidebarBottom>
        </SidebarContainer>
    );
};

export default AdminSidebarComponent;