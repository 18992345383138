import styled from 'styled-components';

export const SidebarContainer = styled.div`
    width: 42%;
    height: 100vh;
    background: #f9f9f9;
    display: flex;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 767px) {
        width: 20%;
    }
    `;
export const SidebarTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    `;
export const SidebarBottom = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    align-self: flex-end;
    `;
export const SidebarLink = styled.a`
    text-decoration: none;
    color: #333;
    font-size: 2rem;
    margin: 0.5rem 0;
    `;
export const SidebarLogo = styled.img`
    width: 50%;
    `;
export const SidebarIcon = styled.img`
    width: 1.5rem;
    margin-right: 0.5rem;
    `;
export const SidebarText = styled.p`
    font-size: 1rem;
    `;
export const SidebarButton = styled.button`
    padding: 0.5rem 1rem;
    background: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    `;
export const SidebarIconContainer = styled.div`
    display: flex;
    align-items: center;
    `;
export const SidebarTextContainer = styled.div`
    display: flex;
    align-items: center;
    `;
export const SidebarButtonContainer = styled.div`
    display: flex;
    align-items: center;
    `;