import styled from 'styled-components';

export const PostEditorH = styled.h1`
  font-size: 2.3rem;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-weight: 100;
`;


export const PostMainContainer = styled.div`
    display: flex;
    flex-direction: row;

    .saveBtn {
        background: beige;
        border: 1px solid #4c4848;
        border-radius: 5px;
        padding: 0.5rem;
        margin: 1rem;
    }

`;

export const PostPictureContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    `;


export const PostToolsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    flex-direction: column;
    margin-left: 2rem;
    
    h3 {
        font-size: 1.5rem;
    }
    
    `;

export const PostEditContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    flex-direction: column;
    margin-left: 2rem;
    max-width: 307px;
`;

export const EditPhotoBtn = styled.button`
    background: beige;
    border: 1px solid #4c4848;
    border-radius: 5px;
    padding: 2rem 3rem;
    margin: 1rem;
    width: 200px;
    height: 80px;
    font-size: 1.5rem;
`;
export const CaptionForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    max-width: 427px;
    
    textarea {
        width: 100%;
        min-height: 49px;
    }
`;

export const PostSaveBtn = styled.button`
    background: rgba(12, 179, 91, 0.38);
    border: 1px solid #4c4848;
    border-radius: 5px;
    padding: 2rem 3rem;
    margin: 1rem;
    min-width: 200px;
    max-width: 427px;
    font-size: 1.5rem;
`;
export const PostViewBtn = styled.button`
    background: rgba(254, 254, 254, 0.92);
    border: 1px solid #4c4848;
    border-radius: 5px;
    padding: 2rem 3rem;
    margin: 1rem;
    min-width: 200px;
    max-width: 427px;
    font-size: 1.5rem;
`;

export const UploadError = styled.div`
    color: red;
    font-size: 1.5rem;
    margin: 1rem;
`;
