// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 62.5%;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans Condensed', sans-serif;
}

a {
  /* margin-left: 3px; */
  /* font-family: 'Nimbus Sans', sans-serif; */
  color: rgba(66, 133, 244, 1);
}
input {
  font-family: inherit;
}

.Toastify__toast-body {
  font-family: inherit !important;
  font-weight: 400 !important;
  font-size: 1.6rem !important;
}

#theme-light,
#theme-dark,
#send_file,
#image {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,mCAAmC;EACnC,kCAAkC;EAClC,8CAA8C;AAChD;;AAEA;EACE,sBAAsB;EACtB,4CAA4C;EAC5C,4BAA4B;AAC9B;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,+BAA+B;EAC/B,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;;;;EAIE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B","sourcesContent":["html {\n  font-size: 62.5%;\n  overflow-y: scroll;\n}\n\n::-webkit-scrollbar {\n  display: none;\n  width: 0px;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-family: 'Open Sans Condensed', sans-serif;\n}\n\na {\n  /* margin-left: 3px; */\n  /* font-family: 'Nimbus Sans', sans-serif; */\n  color: rgba(66, 133, 244, 1);\n}\ninput {\n  font-family: inherit;\n}\n\n.Toastify__toast-body {\n  font-family: inherit !important;\n  font-weight: 400 !important;\n  font-size: 1.6rem !important;\n}\n\n#theme-light,\n#theme-dark,\n#send_file,\n#image {\n  display: none;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\np {\n  margin: 0;\n  padding: 0;\n}\n.fade-enter {\n  opacity: 0;\n}\n\n.fade-enter-active {\n  opacity: 1;\n  transition: opacity 500ms;\n}\n\n.fade-exit {\n  opacity: 1;\n}\n\n.fade-exit-active {\n  opacity: 0;\n  transition: opacity 500ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
