import React from 'react';
import SelectorStyle from './LanguageSelector.style';
import {changeLanguage} from "../../../helpers/helpers";

const LanguageSelector = function() {

    return (
        <SelectorStyle><a href="#" onClick={() => changeLanguage('en')}>English</a> | <a href="#" onClick={() => changeLanguage('uk')}>Українська</a></SelectorStyle>
    );
};
export default LanguageSelector;
