// eslint-disable-next-line
export const themeActionTypes = {
  SET_COLOR_THEME_START: 'SET_COLOR_THEME_START',
  SET_COLOR_THEME: 'SET_COLOR_THEME',
  SET_COLOR_THEME_FAILURE: 'SET_COLOR_THEME_FAILURE',

  SET_COLOR_THEME_LIGHT_START: 'SET_COLOR_THEME_LIGHT_START',
  SET_COLOR_THEME_LIGHT: 'SET_COLOR_THEME_LIGHT',
  SET_COLOR_THEME_LIGHT_FAILURE: 'SET_COLOR_THEME_LIGHT_FAILURE',
};
