import React from 'react';

import AdminSidebarComponent from "../../AdminSidebar/AdminSidebar.component";
import {AdminContent, AdminPageContainer} from "../../Admin.style";
import Icon from "../../../shared/Icon/Icon.style";
import {t} from "i18next";
import {
    AdminListContainer,
    AdminListPagination,
    AdminListTable,
    AdminListTableActionsCell,
    AdminListTableBody,
    AdminListTableBodyCell,
    AdminListTableBodyRow,
    AdminListTableButton,
    AdminListTableHead,
    AdminListTableHeadCell,
    AdminListTableHeadRow,
    PostImagePreview
} from "../AdminList.style";
import {Navigate} from "../../../Post/Post.style";


const AdminListPostsComponent = ({list, page, pageSize, total, count, totalPages, viewCallback, deleteCallback}) => {

    return (
        <AdminPageContainer>
            <AdminSidebarComponent></AdminSidebarComponent>
            <AdminContent>
                <h1>{t('admin.posts.list')}</h1>
                <AdminListContainer>
                    <AdminListTable>
                        <AdminListTableHead>
                            <AdminListTableHeadRow>
                                <AdminListTableHeadCell>{t('admin.posts.post')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell></AdminListTableHeadCell>
                                <AdminListTableHeadCell>{t('admin.posts.profile')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell>{t('admin.posts.date')}</AdminListTableHeadCell>
                                <AdminListTableHeadCell></AdminListTableHeadCell>
                            </AdminListTableHeadRow>
                        </AdminListTableHead>
                        <AdminListTableBody>
                            {list && list.map((post) => (
                                <AdminListTableBodyRow key={post._id}>
                                    <AdminListTableBodyCell>{post.caption}</AdminListTableBodyCell>
                                    <AdminListTableBodyCell>
                                        <a href={post?.image?.[0]?.url} target="_blank">
                                            <PostImagePreview src={post?.image?.[0]?.url}></PostImagePreview>
                                        </a>
                                    </AdminListTableBodyCell>
                                    <AdminListTableBodyCell>
                                        <a href={`/${post.profile.username}`} target="_blank">
                                            {post.profile.username}
                                        </a>
                                    </AdminListTableBodyCell>
                                    <AdminListTableBodyCell>{post.createdAt}</AdminListTableBodyCell>
                                    <AdminListTableActionsCell>
                                        <Navigate to={`/post/${post._id}`} target="_blank">
                                            <AdminListTableButton onClick={viewCallback(post)}><Icon
                                                className='fas fa-eye' fontSize={1}/></AdminListTableButton>
                                        </Navigate>
                                        <AdminListTableButton onClick={deleteCallback(post)}><Icon
                                            className='fas fa-trash' fontSize={1}/></AdminListTableButton>
                                    </AdminListTableActionsCell>
                                </AdminListTableBodyRow>
                            ))}
                        </AdminListTableBody>
                    </AdminListTable>

                    <AdminListPagination>
                        <button disabled={page === 1}>{t('admin.pagination.previous')}</button>
                        <span>{page} / {totalPages}</span>
                        <button disabled={page === totalPages}>{t('admin.pagination.next')}</button>
                        <div className={'right'}>{t('admin.total')}: {total}</div>
                    </AdminListPagination>

                </AdminListContainer>
            </AdminContent>
        </AdminPageContainer>
    );

};

export default AdminListPostsComponent;
