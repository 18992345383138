// Desc: Private route for admin
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { selectIsAuthenticated, selectIsAdmin } from '../redux-sagas/user/user.selector';

const AdminRoute = ({
                          component: Component,
                          routeName = null,
                          isAuthenticated,
                            isAdmin,
                          ...rest
                      }) => {

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    isAdmin ? (
                        <Component {...props} routeName={routeName} />
                    ) : (
                        <Redirect to='/' />
                    )
                ) : <Redirect to='/auth' />
            }
        />
    );
};

AdminRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
    isAuthenticated: selectIsAuthenticated,
    isAdmin: selectIsAdmin,
});

export default connect(mapStateToProps)(AdminRoute);
